/* make the customizations */
$primary: #2f9f2f;
$warning: #ec971f;
$danger: #dc3545;

$theme-colors: (
  'primary': $primary,
  'warning': $warning,
  'danger': $danger,
);

$min-contrast-ratio: 3;
$form-feedback-invalid-color: $warning;
$form-feedback-icon-invalid: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 3.33 25.49' fill='#{$form-feedback-invalid-color}'><path d='M3.33 23.82a1.59 1.59 0 00-1.66-1.66 1.61 1.61 0 00-1.62 1.66 1.65 1.65 0 001.62 1.68 1.6 1.6 0 001.66-1.68zM.61 0h2.11v18.95H.61z'/></svg>");

$form-feedback-valid-color: $primary;
$form-feedback-icon-valid: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 62.04 43.04' fill='#{$form-feedback-valid-color}'><path d='m58.51 0-19 19L38 20.51 22.52 35.98 3.54 17 0 20.54l20.51 20.5.01-.01 2.02 2.01 19-19 1.5-1.5 19-19L58.51 0z' /></svg>");

// $form-floating-height: add(1.5rem, 1px);

/* import bootstrap to set changes */
@import '~bootstrap/scss/bootstrap';

// $form-floating-height: add(2.5rem, $input-height-border);
// @media (min-width: 768px) {
//     $form-floating-height: add(2.5rem, $input-height-border);
// }
